<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="accent text-center"
      space="100"
    >
      <template>
        <v-container>
          <base-section-heading
            title="Contato"
            color="white"
            text-color="white"
          />
          <v-row>
            <v-col>
              <h1>Solicite um orçamento</h1>
              <span class="text-subtitle-1">Um novo conceito em gestão condominial</span>
              <v-row
                align="center"
                class="mt-4 spacer"
                no-gutters
              >
                <v-col cols="1">
                  <img
                    :src="require('@/assets/icons/002-phone.png')"
                    width="35px"
                  >
                </v-col>
                <v-col
                  cols="11"
                  class="text-left pl-2"
                >
                  <span>+55 (34) 3234-6265 | 99790-3597</span>
                </v-col>
                <v-col
                  cols="12"
                  class="pa-2"
                />
                <v-col cols="1">
                  <img
                    :src="require('@/assets/icons/001-email.png')"
                    width="35px"
                  >
                </v-col>
                <v-col
                  cols="11"
                  class="text-left pl-2"
                >
                  <span>contato@singularegestao.com.br</span>
                </v-col>
                <v-col
                  cols="12"
                  class="pa-2"
                />
                <v-col cols="1">
                  <img
                    :src="require('@/assets/icons/001-address.png')"
                    width="35px"
                  >
                </v-col>
                <v-col
                  cols="11"
                  class="text-left pl-2"
                >
                  <span><b>Sede Operacional</b>: Rua Bernardo Guimarães, 325 - Uberlândia, MG<br><b>Sede Administrativa</b>: Avenida dos Vinhedos, 200 - Uberlândia, MG</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    dense
                    label="Nome"
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    dense
                    label="E-mail"
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-text-field
                    dense
                    label="Assunto"
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-textarea
                    label="Mensagem"
                    filled
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn>Enviar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionContactUs',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
